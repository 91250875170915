import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getAllBusinessesData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React from "react";
import Utils from "utils/Utils";

const Onboarding = () => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const { DEFAULT_APP_URL } = Utils.getProcessEnvVars();

  const { extraData = {}, settings = {} } = useSelector(getAllBusinessesData);

  const [
    useDesktopVersionMessageA,,
    useDesktopVersionMessageC
  ] = messages.useDesktopVersion;

  const {
    lastEntitiesAutoSetAt,
    uncategorizedRpaEnabled,
    reconciliationRpaEnabled,
    lastGptAiCategorizedAt
  } = extraData;

  const rpaMode = uncategorizedRpaEnabled !== undefined || reconciliationRpaEnabled !== undefined;

  const settingUp = uncategorizedRpaEnabled === undefined && reconciliationRpaEnabled === undefined
    ? !lastEntitiesAutoSetAt
    : !lastGptAiCategorizedAt;

  if (settingUp || (rpaMode && (settings.rpaAutoAskClient === undefined))) {
    return (
      <div className={Css.onboarding}>
        <div className={Css.errorContainer}>
          <Icons.Desktop weight="thin" className={Css.icon} />
          <div className={Css.title}>{useDesktopVersionMessageA}</div>
          <div className={Css.description}>
            {Utils.replaceTextVars(useDesktopVersionMessageC, { appDomain: DEFAULT_APP_URL })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={Css.onboarding}>
      <div className={Css.content}>
        <Icons.Spinner />
        <span>{uiTexts.settingUp}</span>
      </div>
    </div>
  );
};

export default React.memo(Onboarding);
